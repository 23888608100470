<template>
  <div class="system-management">
    <!-- <div class='part-title'>{{title}}</div> -->
    <div class="nav-header margin-top-bottom-twenty border-radius-6 box-shadow-light-grey">
      <el-menu :default-active="activeSubMenu" router mode="horizontal"
       class='border-radius-6'
       @select='handleMenuClick'
      >
       <el-menu-item  class='font-eighteen margin-right-23 ' :index='item.path' v-for='item in  systemMenu' :key='item.path'>
           <i class='iconfont' :class='item.icon'></i>
           <span  slot='title'>{{item.title}}</span>
       </el-menu-item>
      </el-menu>
    </div>

      <router-view></router-view>

  </div>
</template>
<script>
import { systemMenu } from '@/assets/js/nav.js'
import { mapActions } from 'vuex'
export default {
  name: 'SystemManagement',
  data () {
    return {
      activeSubMenu: this.$route.meta.submenu,
      systemMenu
    }
  },
  watch: {
    $route: {
      handler (newV) {
        this.activeSubMenu = newV.meta.submenu || newV.path
      },
      immediate: true
    }
  },
  methods: {
    ...mapActions({
      addRouterHistory: 'addRouterHistory'
    }),
    handleMenuClick (path) {
      const menuData = this.systemMenu.find(item => item.path === path)
      this.addRouterHistory({
        params: {},
        title: menuData.title,
        path: menuData.path
      })
    }
  }
  // computed:{
  //    title(){
  //        return this.titleArr.find(item=>item.routeName === this.$route.name).title
  //    }
  // },
  // watch:{
  //     $route:{
  //         handler(newV){
  //         },
  //         immediate:true
  //     }
  // }
}
</script>

<style lang="scss" scoped>
.nav-header{
  background:#fff;
  box-sizing: border-box;
  padding:0 5px;
  /deep/ .el-menu-item.is-active{
  border-right:0;
}
/deep/ .el-menu-item,
.el-submenu__title {
  height:34px;
}
/deep/ .el-menu{
  height:52px !important;
  display: flex;
  align-items: center;
  .el-menu-item{
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
}
.system-management{
  // height:calc(100vh - 200px)
}

</style>
